import "./plugins/jquery-exitintent.min.js";
import timeCounter from "./components/time-counter";

$(document).ready(function(){
    timeCounter.initDataSelectors();
    //Notification Close Cookies
    $('.clientAlertModal').on('hide.bs.modal', function(){
        var isChecked = $(this).find('#checkClose').is(':checked');
        if( isChecked ){
            var days, date, expires, name;
            name = $(this).attr('id');
            days = $(this).data('close-days');
            if( days ){
                date = new Date();
                date.setDate(date.getDate()+days);
                expires = "expires="+date.toUTCString();
            }else{
                expires = "";
            }
            document.cookie = `${name}=; ${expires}`;
        }
    });

    $('.clientAlert').on('close.bs.alert', function(){
        var days, date, expires, name;
        name = $(this).attr('id');
        days = $(this).data('close-days');
        if( days ){
            date = new Date();
            date.setDate(date.getDate()+days);
            expires = "expires="+date.toUTCString();
        }else{
            expires = "";
        }
        document.cookie = `${name}=; ${expires}`;
    });

    // Modal show first 
    var firstModalDelay = $('.clientAlertModalOnLoad').first().data('onload-delay');
    setTimeout(function(){
        $('.clientAlertModalOnLoad').first().modal('show');
    }, firstModalDelay);

    // Modal show next
    $('.clientAlertModalOnLoad').on('hidden.bs.modal', function(){ 
        var nextEl = $(this).next();
        while(!nextEl.hasClass('clientAlertModalOnLoad')){
            nextEl = nextEl.next();
            if(!nextEl.hasClass('clientAlertModalOnLoad') && !nextEl.hasClass('clientAlertModalOnExit')){
                break;
            }
        };

        var nextElDelay = nextEl.data('onload-delay');
        if (nextEl.length && nextEl.hasClass('clientAlertModalOnLoad')){
            setTimeout(function(){
                nextEl.modal('show');
            }, nextElDelay);
        }
    });

    $('.clientAlertModalOnExit').on('hidden.bs.modal', function(){
        var nextEl = $(this).next();

        while(!nextEl.hasClass('clientAlertModalOnExit')){
            nextEl = nextEl.next();
            if(!nextEl.hasClass('clientAlertModalOnLoad') && !nextEl.hasClass('clientAlertModalOnExit')){
                break;
            }
        };

        if (nextEl.length && nextEl.hasClass('clientAlertModalOnExit')){
            nextEl.modal('show');
        }
    });

});

$.exitIntent('enable');
$(document).bind('exitintent', function() {
    $('.clientAlertModalOnExit').first().modal('show');
});